import { useMutation } from "@tanstack/react-query";
import {
  createDebitTransfer,
  CreateDebitTransferBody,
  CreateDebitTransferResponse,
} from "../../../services/transactionsService";
import useCreateAccountHolderNotesMutation from "./useCreateAccountHolderNotesMutation";
import { convertToAdyenNumber } from "../../../utils/number";

type CreateDebitTransferBodyWithAccountHolderCode = CreateDebitTransferBody & {
  accountHolderCode: string;
};

export default function useCreateDebitTransferMutation() {
  const { mutateAsync: createNote } = useCreateAccountHolderNotesMutation();

  return useMutation<
    CreateDebitTransferResponse,
    string,
    CreateDebitTransferBodyWithAccountHolderCode
  >(
    (body) =>
      createDebitTransfer({
        ...body,
        amount: {
          value: convertToAdyenNumber(body.amount.value).toString(),
          currency: body.amount.currency,
        },
      }),
    {
      async onSuccess(data, body) {
        await createNote({
          accountHolderCode: body.accountHolderCode,
          freeText: `Transfer: ${data.id} - Account debit created with amount: ${body.amount.value} ${body.amount.currency} and description: ${body.notes}`,
        });
      },
    }
  );
}
